import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51PntHkRvFy2hSo8saPLKRvXshyqMZjAdfUAiqtSDAl9ni4Rzjoq9DsQvbY4MsprYqf4kcSNmMXekDuZJijnauta900AGEItVjC');

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
