import React from 'react';
import { useHistory } from 'react-router-dom'; // Assuming you're using react-router for navigation

const ThankYou = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/contact"); // Navigates to the previous page
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-black text-white">
      <button
        onClick={handleBack}
        className="absolute top-4 left-4 bg-white text-black py-2 px-4 rounded hover:bg-gray-300"
      >
        Back
      </button>
      <div className="text-center">
        <h1
          className="text-4xl font-semibold mb-6"
          style={{
            textShadow: `
              2px 2px 0 orange, 
              -2px -2px 0 red, 
              2px -2px 0 yellow, 
              -2px 2px 0 brown`
          }}
        >
          Your Request has been Submitted
        </h1>
       
        <p className="text-lg mb-6">
          One of our team members will reach out to you shortly to help you get started and answer any questions you may have.
        </p>
        <p className="text-sm">If you don't receive an email, please check your spam folder.</p>
        <p className="text-sm">
          You can also contact us directly at{' '}
          <a href="farida@farz.ai" className="underline">
          farida@farz.ai
          </a>
        </p>
        <p className="text-sm">Thank you for choosing our premium service!</p>
      </div>
    </div>
  );
};

export default ThankYou;
