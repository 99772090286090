import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import swal from 'sweetalert2';
import CreditCardImage from '../../Assets/images/creditcard.jpg'; // Replace with your actual path
import { useHistory, Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";

const PaymentForm = ({ planId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [cardholderName, setCardholderName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(29000);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      swal.fire({
        icon: 'error',
        title: 'Payment Failed',
        text: error.message,
        confirmButtonText: 'Try Again',
        customClass: {
          popup: 'center-swal'
        }
      });
      return;
    }

    const paymentMethodId = paymentMethod.id;

    try {
      const headers= {
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI3NDE5ODY2LCJpYXQiOjE3MjQ4Mjc4NjYsImp0aSI6Ijc5OWQ1OWM2MDc1ODQzMjE5N2U0MWMxNWM2MTFjODEzIiwidXNlcl9pZCI6Mn0.2PzHT8N6mIedZ4f217GFt5APRjF4_F0zbzCE5vxww3c`
        }
        
        const response = await axios.post('https://voiceamplifiedbackendserver.eastus.cloudapp.azure.com/subscription/', {
          plan_id: planId,
          payment_method_id: paymentMethodId,
        }, { headers });
        
        console.log('API Response:', response.data); 
        if (response.data.subscription_id) {
          swal.fire({
            icon: 'success',
            title: 'Payment Successful',
            text: 'Your subscription was created successfully!',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'center-swal'
            }
          });

          const timer = setTimeout(() => {
            history.push('/thankyou');
          }, 1500);
          return () => clearTimeout(timer);
        } else {
          throw new Error('Subscription creation failed');
        }
      
      } catch (error) {
        console.error('Error details:', error);  // Detailed error log
        setError(error.response ? error.response.data.error : error.message);
        swal.fire({
          icon: 'error',
          title: 'Payment Error',
          text: 'An error occurred while processing your payment.',
          confirmButtonText: 'Try Again',
          customClass: {
            popup: 'center-swal'
          }
        });
      }

    setLoading(false);
  };

  return (
    <div
      className="relative flex flex-col items-center justify-center min-h-screen"
      style={{
        background: 'linear-gradient(to right, #ff7e5f, #feb47b)', // Orange gradient
        overflow: 'hidden',
      }}
    >
      <div className="relative w-full max-w-4xl p-8 bg-[#0c1322] rounded-xl shadow-lg" style={{ zIndex: 10 }}>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <h2 className="text-5xl font-bold text-white">Payment <span style={{ color: '#ff7e5f' }}>Method</span></h2>
            <p className="text-white mt-4 mb-8">You can add cards, bank accounts, and other payment methods to your account.</p>
            <img src={CreditCardImage} alt="Credit Card" className="rounded-lg shadow-xl max-w-xs mx-auto mt-4 lg:ml-0 lg:mr-0" />
          </div>
          <form onSubmit={handleSubmit} className="w-full lg:w-1/2 lg:pl-12">
            <h3 className="text-2xl font-semibold text-white mb-4">Credit Card Payments</h3>
            <label className="block mb-3">
              <span className="text-white">Cardholder Name</span>
              <input 
                type="text" 
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)} 
                className="mt-1 block w-full p-2 bg-transparent border border-gray-600 rounded-md shadow-sm focus:ring focus:ring-opacity-50 focus:ring-orange-300 text-white" 
                placeholder="John Clifford"
                required 
              />
            </label>
            <label className="block mb-3">
              <span className="text-white">Card Number</span>
              <CardElement 
                className="mt-1 p-2 bg-transparent border border-gray-600 rounded-md shadow-sm"
                options={{
                  style: {
                    base: {
                      color: '#ffffff',
                      fontSize: '16px',
                      '::placeholder': {
                        color: '#ffffff99',
                      },
                    },
                    invalid: {
                      color: '#ff7e5f',
                    },
                  },
                }}
              />
            </label>

            <div className="flex justify-between items-center mt-4">
              <h4 className="text-lg font-semibold text-white">Total:</h4>
              <span className="text-xl font-bold text-white">USD {amount.toLocaleString('id-ID')}</span>
            </div>
            <button 
              type="submit" 
              disabled={loading || !stripe}
              className={`mt-6 w-full py-2 rounded-md text-white ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-600 hover:bg-orange-700'}`}
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
            <div>
              <Link to="/pricing" className="text-[#ff7e5f] hover:underline flex items-center m-6">
                <FaArrowLeft className="mr-2" /> Back 
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
